import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Link } from '@mui/material'

import logo from '../assets/joywallet-logo.png'

import Google from '../components/auth/Google'
import { ENABLE_PUBLIC_LOGIN } from '../utils/constants'

export default function () {
	return (
		<>
			<header>
				<Link to="/" component={RouterLink}>
					<img src={logo} className="logo" alt="logo" />
				</Link>

				<div>
					{ENABLE_PUBLIC_LOGIN && (
						<Button to="/auth" component={RouterLink} variant="contained" style={{ margin: '0 5px' }}>
							LOG IN
						</Button>
					)}

					<Google />
				</div>
			</header>

			<p>Please login to continue.</p>
		</>
	)
}
