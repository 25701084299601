import React, { Fragment } from 'react'
import { useIdentityContext } from 'react-netlify-identity'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { Menu, MenuItem, Link, ListItemIcon, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Tooltip from '@mui/material/Tooltip'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import logo from '../../assets/joywallet-logo.png'

import Navigation from './Navigation'

export default function () {
	const { logoutUser } = useIdentityContext()
	let history = useHistory()

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleLogout = async () => {
		setAnchorEl(null)
		await logoutUser()
		history.push('/login')
	}

	return (
		<header>
			<Link to="/" component={RouterLink}>
				<img src={logo} className="logo" alt="logo" />
			</Link>

			<div className="right">
				<Navigation />

				<Tooltip title="Navigation Menu">
					<IconButton variant="outlined" onClick={e => setAnchorEl(e.currentTarget)} aria-controls="long-menu">
						<MenuIcon />
					</IconButton>
				</Tooltip>

				<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
					<MenuItem onClick={handleLogout}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						Logout
					</MenuItem>
				</Menu>
			</div>
		</header>
	)
}
