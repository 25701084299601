import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'
import { connect } from './contentful'

let CACHED_CATEGORIES = null
let CACHED_SUB_CATEGORIES = null

export async function getCategories() {
	if (CACHED_CATEGORIES) return CACHED_CATEGORIES

	const contentful = await connect()
	const fields = ['name', 'slug'].map(fieldName => `fields.${fieldName}`)

	const filter = {
		content_type: 'category',
		limit: 1000,
		select: fields.join(','),
	}
	const { items } = await contentful.getEntries(filter)

	CACHED_CATEGORIES = items.map(i => ({
		id: i.sys.id,
		name: i.fields.name,
		slug: i.fields.slug,
	}))

	return CACHED_CATEGORIES
}

export async function getSubCategories() {
	if (CACHED_SUB_CATEGORIES) return CACHED_SUB_CATEGORIES

	const contentful = await connect()
	const fields = ['name', 'slug', 'parentCategory'].map(fieldName => `fields.${fieldName}`)

	const filter = {
		content_type: 'subCategory',
		limit: 1000,
		select: fields.join(','),
	}
	const { items } = await contentful.getEntries(filter)

	CACHED_SUB_CATEGORIES = items.map(i => ({
		id: i.sys.id,
		name: i.fields.name,
		slug: i.fields.slug,
		parentId: i.fields.parentCategory?.sys.id,
	}))

	return CACHED_SUB_CATEGORIES
}

export function getCategorization(id) {
	const foundCategory = CACHED_CATEGORIES.find(c => c.id === id)
	if (foundCategory) {
		return {
			categoryId: foundCategory.id,
		}
	}

	const foundSubCategory = CACHED_SUB_CATEGORIES.find(sc => sc.id === id)

	if (foundSubCategory) {
		return {
			categoryId: foundSubCategory.parentId,
			subCategoryId: foundSubCategory.id,
		}
	}
}
