import { marked } from 'marked'
import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'
import { connect } from './contentful'

let CACHED_WIDGETS = null

export async function getAllWidgets() {
	if (CACHED_WIDGETS) return CACHED_WIDGETS

	const contentful = await connect()
	const fields = ['name', 'title', 'tableContent', 'category'].map(fieldName => `fields.${fieldName}`)
	fields.push('sys.createdAt', 'sys.updatedAt')
	const filter = {
		content_type: 'comparisonTable',
		limit: 1000,
		select: fields.join(','),
	}
	const { items } = await contentful.getEntries(filter)

	CACHED_WIDGETS = items.map(i => ({
		id: i.sys.id,
		name: i.fields.name,
		title: i.fields.title,
		tableContent: marked(i.fields.tableContent),
		categories: [
			{
				id: i.fields.category.sys.id,
				title: i.fields.category.fields.name,
				slug: i.fields.category.fields.slug,
				url: `https://joywallet.com/topic/${i.fields.category.fields.slug}/`,
				contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${i.fields.category.sys.id}`,
				published: i.fields.category.sys.createdAt,
			},
		],

		contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${i.sys.id}`,
		createdAt: i.sys.createdAt,
		updatedAt: i.sys.updatedAt,
		displayName: `Comparison Table - ${i.fields.name}`,
	}))

	return CACHED_WIDGETS
}

export function getCategorizationComparisonTable(categoryId) {
	const associatedWidget = CACHED_WIDGETS.find(w => w.categories.find(c => c.id === categoryId))

	return associatedWidget ? [associatedWidget] : []
}
