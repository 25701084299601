import { connect } from './contentful'
import { getLinkedPosts } from './posts'
import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'

let CACHED_WIDGETS = null
let CACHED_LINKED_WIDGETS = null

export async function getAllWidgets() {
	if (CACHED_WIDGETS) return CACHED_WIDGETS

	const contentful = await connect()
	const fields = ['internalName'].map(fieldName => `fields.${fieldName}`)
	fields.push('sys.createdAt', 'sys.updatedAt')
	const filter = {
		content_type: 'postBodyOurPicks',
		limit: 1000,
		select: fields.join(','),
	}
	const { items } = await contentful.getEntries(filter)

	CACHED_WIDGETS = items.map(i => ({
		id: i.sys.id,
		name: i.fields.internalName,
		title: i.fields.internalName,
		contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${i.sys.id}`,
		createdAt: i.sys.createdAt,
		updatedAt: i.sys.updatedAt,
		displayName: `Our Picks - ${i.fields.internalName}`,
	}))

	return CACHED_WIDGETS
}

export async function getOurPicksMap() {
	const map = new Map()

	const widgets = await getAllWidgets()

	widgets.map(w => map.set(w.id, w))

	return map
}

export async function getParentPosts(widgets) {
	if (CACHED_LINKED_WIDGETS) return CACHED_LINKED_WIDGETS
	CACHED_LINKED_WIDGETS = getLinkedPosts(widgets)
	return CACHED_LINKED_WIDGETS
}

