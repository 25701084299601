import { useEffect, useState, Fragment } from 'react'
import { getAllWidgets } from '../services/sponsored-block'

import SponsoredBlockModal from './modals/SponsoredBlockModal'
import WidgetsFilterBar from './WidgetsFilterBar'
import WidgetsTree from './WidgetsTree'
import Loading from './Loading'

const title = 'Sponsored Block @ Sub Categories'

export default function () {
	const [loading, setLoading] = useState()
	const [data, setData] = useState([])
	const [displayedData, setDisplayedData] = useState([])
	const [selectedItem, setSelectedItem] = useState(null)

	useEffect(async () => {
		try {
			setLoading(true)

			const widgets = await getAllWidgets()

			const filledWidgets = widgets.map(w => ({ ...w, nodes: w.subcategories }))
			setData(filledWidgets)

			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error(err.message || err)
			alert('Error while loading data.')
		}
	}, [])

	return (
		<Fragment>
			<WidgetsFilterBar data={data} loading={loading} onFilter={d => setDisplayedData(d)} />
			<Loading loading={loading} />
			<WidgetsTree title={title} loading={loading} displayedData={displayedData} onPreview={setSelectedItem} showNodesNumber={true} />
			<SponsoredBlockModal title={title} widget={selectedItem} onClose={() => setSelectedItem(null)} />
		</Fragment>
	)
}
