import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'
import { richTextOptions } from '../utils/richTextOptions'
import { connect } from './contentful'
import { getLinkedPosts } from './posts'

let CACHED_WIDGETS = null
let CACHED_LINKED_WIDGETS = null

export async function getAllWidgets() {
	if (CACHED_WIDGETS) return CACHED_WIDGETS

	const contentful = await connect()
	const fields = ['name', 'richSponsoredText', 'categories', 'subcategories'].map(fieldName => `fields.${fieldName}`)
	fields.push('sys.createdAt', 'sys.updatedAt')
	const filter = {
		content_type: 'sponsoredBlock',
		limit: 1000,
		select: fields.join(','),
	}
	const { items } = await contentful.getEntries(filter)

	CACHED_WIDGETS = items.map(i => ({
		id: i.sys.id,
		name: i.fields.name,
		richSponsoredText: documentToHtmlString(i.fields.richSponsoredText, richTextOptions),

		categories:
			i.fields.categories?.map(c => ({
				id: c.sys.id,
				title: c.fields.name,
				slug: c.fields.slug,
				url: `https://joywallet.com/topic/${c.fields.slug}/`,
				contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${c.sys.id}`,
				published: c.sys.createdAt,
			})) || [],

		subcategories:
			i.fields.subcategories?.map(c => ({
				id: c.sys.id,
				title: c.fields.name,
				slug: c.fields.slug,
				// parentName: c.fields.parentCategory?.fields.name,
				// parentSlug: c.fields.parentCategory?.fields.slug,
				// url: `https://joywallet.com/topic/${c.fields.parentCategory?.fields.slug}/${c.fields.slug}/`,
				contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${c.sys.id}`,
				published: c.sys.createdAt,
			})) || [],

		contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${i.sys.id}`,
		createdAt: i.sys.createdAt,
		updatedAt: i.sys.updatedAt,
		displayName: `Sponsored Block - ${i.fields.name}`,
	}))

	return CACHED_WIDGETS
}

export async function getSponsoredBlocksMap() {
	const map = new Map()

	const widgets = await getAllWidgets()

	widgets.map(w => map.set(w.id, w))

	return map
}

export function getCategorizationSponsoredBlock(categoryId, subCategoryId) {
	let associatedWidget = null

	if (subCategoryId) {
		associatedWidget = CACHED_WIDGETS.find(w => w.subcategories.find(sc => sc.id === subCategoryId) || w.categories.find(c => c.id === categoryId))
	} else if (categoryId) {
		associatedWidget = CACHED_WIDGETS.find(w => w.categories.find(c => c.id === categoryId))
	}

	return associatedWidget ? [associatedWidget] : []
}

export async function getParentPosts(widgets) {
	if (CACHED_LINKED_WIDGETS) return CACHED_LINKED_WIDGETS
	CACHED_LINKED_WIDGETS = getLinkedPosts(widgets)
	return CACHED_LINKED_WIDGETS
}

