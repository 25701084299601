import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'
import { connect } from './contentful'

import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { richTextOptions } from '../utils/richTextOptions'
import { getLinkedPosts } from './posts'

let CACHED_WIDGETS = null
let CACHED_LINKED_WIDGETS = null

export async function getAllWidgets() {
	if (CACHED_WIDGETS) return CACHED_WIDGETS

	const contentful = await connect()
	const fields = ['name', 'title', 'richContent', 'image', 'ctaText', 'tracking'].map(fieldName => `fields.${fieldName}`)
	fields.push('sys.createdAt', 'sys.updatedAt')
	const filter = {
		content_type: 'ctaWidget',
		limit: 1000,
		select: fields.join(','),
	}
	const { items } = await contentful.getEntries(filter)

	CACHED_WIDGETS = items.map(i => ({
		id: i.sys.id,
		name: i.fields.name,
		title: i.fields.title,
		richContent: documentToHtmlString(i.fields.richContent, richTextOptions),
		image: `${i.fields.image?.fields?.file?.url}?w=200`,
		ctaText: i.fields.ctaText,
		ctaTrackingCode: i.fields.tracking?.fields?.code,
		ctaTrackingUrl: i.fields.tracking?.fields?.url,
		contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${i.sys.id}`,
		createdAt: i.sys.createdAt,
		updatedAt: i.sys.updatedAt,
		displayName: `CTA Widget - ${i.fields.name}`,
	}))

	return CACHED_WIDGETS
}

export async function getCTAWidgetsMap() {
	const map = new Map()

	const widgets = await getAllWidgets()

	widgets.map(w => map.set(w.id, w))

	return map
}

export async function getParentPosts(widgets) {
	if (CACHED_LINKED_WIDGETS) return CACHED_LINKED_WIDGETS
	CACHED_LINKED_WIDGETS = getLinkedPosts(widgets)
	return CACHED_LINKED_WIDGETS
}
