import { createClient } from 'contentful'
import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'
import { createClient as cmCreateClient } from 'contentful-management'

import _ from 'lodash'

let contentful
let contentfulCM

let CD_KEY = null
let CM_KEY = null

export const setKeys = ({ cdKey, cmKey }) => {
	CD_KEY = cdKey
	CM_KEY = cmKey
}

export const connect = async () => {
	if (!contentful) {
		contentful = await createClient({
			accessToken: CD_KEY,
			environment: CONTENTFUL_ENV,
			space: CONTENTFUL_SPACE,
		})
	}
	return contentful
}

export const connectCM = async () => {
	if (!contentfulCM) {
		const client = await cmCreateClient({
			accessToken: CM_KEY,
		})

		let space = await client.getSpace(CONTENTFUL_SPACE)
		contentfulCM = await space.getEnvironment(CONTENTFUL_ENV)
	}

	return contentfulCM
}

