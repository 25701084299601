import React from 'react'
import Credentials from '../components/auth/Credentials'

function AuthPage() {
	return (
		<>
			<Credentials />
		</>
	)
}

export default AuthPage
