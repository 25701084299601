import React, { useEffect, useState, useMemo, Fragment, useContext } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { IdentityContextProvider, useIdentityContext } from 'react-netlify-identity'
import { Container } from '@mui/material'

import { ENABLE_PUBLIC_LOGIN, SITE_URL } from './utils/constants'

import LoginPage from './pages/LoginPage'
import CTAWidget from './pages/CTAWidget'
import TopStckybar from './pages/TopStckybar'
import MyFinance from './pages/MyFinance'
import Tracking from './pages/Tracking'
import SponsoredBlock from './pages/SponsoredBlock'
import CommonBlock from './pages/CommonBlock'
import ComparisonTable from './pages/ComparisonTable'
import PostOutline from './pages/PostOutline'
import Home from './pages/Home'
import AuthPage from './pages/AuthPage'

import LoadContentful from './components/LoadContentful'
import TopOffers from './pages/TopOffers'
import OurPicks from './pages/OurPicks'

export default function App() {
	return (
		<Container maxWidth="xl">
			<IdentityContextProvider url={SITE_URL}>
				<BrowserRouter>
					<Switch>
						<PublicRoute path="/login" exact={true} component={LoginPage} />
						{ENABLE_PUBLIC_LOGIN && <PublicRoute path="/auth" exact={true} component={AuthPage} />}
						<PrivateRoute path="/" exact={true} component={Home} />
						<ContentfulRoute path="/cta-widget" exact={true} component={CTAWidget} />
						<ContentfulRoute path="/top-sticky-bar" exact={true} component={TopStckybar} />
						<ContentfulRoute path="/my-finance" exact={true} component={MyFinance} />
						<ContentfulRoute path="/tracking" exact={true} component={Tracking} />
						<ContentfulRoute path="/sponsored-block" exact={true} component={SponsoredBlock} />
						<ContentfulRoute path="/common-block" exact={true} component={CommonBlock} />
						<ContentfulRoute path="/comparison-table" exact={true} component={ComparisonTable} />
						<ContentfulRoute path="/post" exact={true} component={PostOutline} />
						<ContentfulRoute path="/top-offers" exact={true} component={TopOffers} />
						<ContentfulRoute path="/our-picks" exact={true} component={OurPicks} />
						<Route component={() => <Redirect to="/login" />} />
					</Switch>
				</BrowserRouter>
			</IdentityContextProvider>
		</Container>
	)
}

const PublicRoute = props => {
	const { isLoggedIn } = useIdentityContext()

	return isLoggedIn ? <Redirect to="/" /> : <Route {...props} />
}

const PrivateRoute = props => {
	const { isLoggedIn } = useIdentityContext()

	return isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />
}

const ContentfulRoute = props => {
	const { isLoggedIn } = useIdentityContext()

	if (!isLoggedIn) return <Redirect to="/login" />

	return (
		<LoadContentful>
			<Route {...props} />
		</LoadContentful>
	)
}

