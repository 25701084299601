import { useEffect, useState, Fragment } from 'react'

import { getAllPosts } from '../services/posts-outline'
import PostsFilterBar from '../components/PostsFilterBar'
import Loading from '../components/Loading'
import TreePagination from '../components/TreePagination'
import Layout from '../components/layout/Layout'

const title = 'Post'

export default function () {
	const [loading, setLoading] = useState()
	const [data, setData] = useState([])
	const [displayedData, setDisplayedData] = useState([])

	useEffect(async () => {
		try {
			setLoading(true)
			const posts = await getAllPosts()

			setData(posts)
			setDisplayedData(posts)
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error(err.message || err)
			alert('Error while loading data.')
		}
	}, [])

	return (
		<Layout>
			<PostsFilterBar data={data} loading={loading} onFilter={d => setDisplayedData(d)} />
			<Loading loading={loading} withMessage={true} />

			<TreePagination
				title={title}
				loading={loading}
				displayedData={displayedData}
				onPreview={p => window.open(p?.url, '_blank')}
				onPreviewText="Open"
				showNodesNumber={false}
			/>
		</Layout>
	)
}
