import { Box, Modal, Typography } from '@mui/material'
import React from 'react'

export default function CtaWidgetModal({ title, onClose, widget }) {
	return (
		<Modal open={widget !== null} onClose={onClose}>
			<div className="modal">
				<div className="title">{title}</div>

				<div className="modal-container">
					<p className="header">Internal Name</p>
					<p>{widget?.name}</p>

					<p className="header">Title</p>
					<p>{widget?.title}</p>

					<p className="header">Table Content</p>
					<p dangerouslySetInnerHTML={{ __html: widget?.tableContent }} />
				</div>
			</div>
		</Modal>
	)
}
