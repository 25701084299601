import { useEffect, useState, Fragment } from 'react'
import { getAllPosts, fillWidgetWithPosts } from '../services/posts'
import { getAllWidgets, getParentPosts } from '../services/common-block'

import WidgetsFilterBar from '../components/WidgetsFilterBar'
import WidgetsTree from '../components/WidgetsTree'
import Loading from '../components/Loading'
import Layout from '../components/layout/Layout'

const title = 'Common Block'

export default function () {
	const [loading, setLoading] = useState()
	const [data, setData] = useState([])
	const [displayedData, setDisplayedData] = useState([])

	useEffect(async () => {
		try {
			setLoading(true)
			const posts = await getAllPosts()
			const widgets = await getAllWidgets()
			const links = await getParentPosts(widgets)

			const filledWidgets = fillWidgetWithPosts({ widgets, links, nodes: posts })

			setData(filledWidgets)
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error(err.message || err)
			alert('Error while loading data.')
		}
	}, [])

	return (
		<Layout>
			<WidgetsFilterBar data={data} loading={loading} onFilter={d => setDisplayedData(d)} />
			<Loading loading={loading} />
			<WidgetsTree title={title} loading={loading} displayedData={displayedData} showNodesNumber={true} />
		</Layout>
	)
}
