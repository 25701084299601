import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'
import { connect } from './contentful'

const PAGE_SIZE = 1000
const MAX_NUMBER_OF_PAGES = null //for test only
let CACHED_POSTS = null

export async function getAllPosts() {
	if (CACHED_POSTS) return CACHED_POSTS

	let posts = []

	const firstPage = await getPostsPage(0)

	posts.push(...firstPage.items)

	const numberOfPages = MAX_NUMBER_OF_PAGES || Math.ceil(firstPage.total / PAGE_SIZE)

	for (let i = 1; i < numberOfPages; i++) {
		let pagePosts = await getPostsPage(i)
		posts.push(...pagePosts.items)
	}

	CACHED_POSTS = posts?.map(i => ({
		id: i.sys.id,
		title: i.fields?.title,
		published: i.fields.updatedDate ? new Date(i.fields.updatedDate) : new Date(i.fields.publishDate),
		slug: i.fields.slug,
		contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${i.sys.id}`,
		url: `https://joywallet.com/article/${i.fields.slug}/`,
		copyUrl: `https://joywallet.com/article/${i.fields.slug}/`,
		copyTitle: i.fields?.title,
		copySlug: i.fields.slug,
	}))

	return CACHED_POSTS
}

async function getPostsPage(pageIndex) {
	const fields = ['title', 'slug', 'publishDate', 'updatedDate'].map(name => `fields.${name}`)
	fields.push('sys')

	const filter = {
		'content_type': 'post',
		'limit': PAGE_SIZE,
		'skip': pageIndex * PAGE_SIZE,
		'fields.unpublishedPost': false,
		'select': fields.join(','),
		'include': 0,
	}

	const contentful = await connect()

	const response = await contentful.getEntries(filter)

	return response
}

export async function getLinkedPosts(items) {
	const CHUNK_SIZE = 8

	const ids = items.map(item => item.id)

	const result = []

	const chunks = _.chunk(ids, CHUNK_SIZE)

	for (let i = 0; i < chunks.length; i++) {
		await result.push(...(await Promise.all(chunks[i].map(id => getLinkedPostsChunk(id)))))
	}

	return result
}

async function getLinkedPostsChunk(id) {
	const contentful = await connect()

	const filter = {
		'sys.contentType.sys.id': 'post',
		'links_to_entry': id,
		'select': 'sys.id',
		'content_type': 'post',
		'fields.unpublishedPost': false,
		'limit': 1000,
	}

	const result = await contentful.getEntries(filter)
	const posts = result.items.map(item => item.sys.id)

	return {
		id,
		posts,
	}
}

export function fillWidgetWithPosts({ widgets, links, nodes }) {
	const filledWidgets = widgets
		.map(widget => ({
			...widget,
			nodes: links.find(link => link.id === widget.id)?.posts,
		}))
		.map(widget => ({
			...widget,
			nodes: widget.nodes.map(nodeId => nodes.find(({ id }) => id == nodeId)),
		}))

	return filledWidgets
}
