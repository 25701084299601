import React, { useState } from 'react'
import { Button } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'

import { useIdentityContext } from 'react-netlify-identity'

function Google() {
	const { loginProvider } = useIdentityContext()

	const handleLogin = () => {
		loginProvider('google')
	}

	return (
		<Button variant="contained" onClick={handleLogin} startIcon={<GoogleIcon />}>
			Sign In
		</Button>
	)
}

export default Google
