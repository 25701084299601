import { CircularProgress } from '@mui/material'
import React from 'react'

export default function Loading({ loading, withMessage = false }) {
	if (!loading) return null

	return (
		<div className="loading-container">
			<CircularProgress />
			{withMessage && <span>Doing the heavy lifting! This could take up to a minute.</span>}
		</div>
	)
}
