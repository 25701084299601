import { useEffect, useState, useContext, Fragment } from 'react'

import _ from 'lodash'

import { Box, Button, CircularProgress, FormControl, InputLabel, Link, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'

export default function WidgetsFilterBar({ data, onFilter, loading }) {
	const [filterByUsed, setFilterByUsed] = useState('')
	const [filterByText, setFilterBytext] = useState('')
	const [sortWidgetBy, setSortWidgetBy] = useState('')
	const [sortPostsBy, setSortPostsBy] = useState('')

	useEffect(() => {
		let newFiltered = data

		if (filterByText) {
			newFiltered = newFiltered.filter(
				w =>
					w.name.toLowerCase().includes(filterByText.toLowerCase()) ||
					w.nodes.filter(p => p.title.toLowerCase().includes(filterByText.toLowerCase())).length > 0,
			)

			newFiltered = newFiltered.map(w => {
				return w.name.toLowerCase().includes(filterByText.toLowerCase())
					? w
					: { ...w, nodes: w.nodes.filter(p => p.title.toLowerCase().includes(filterByText.toLowerCase())) }
			})
		}

		switch (filterByUsed) {
			case true:
				newFiltered = newFiltered.filter(w => w.nodes.length > 0)
				break

			case false:
				newFiltered = newFiltered.filter(w => w.nodes.length === 0)
				break
		}

		switch (sortWidgetBy) {
			case 'name':
				newFiltered = _.sortBy(newFiltered, w => w.name)
				break

			case 'usage':
				newFiltered = _.sortBy(newFiltered, w => w.nodes.length).reverse()

				break

			case 'created':
				newFiltered = _.sortBy(newFiltered, w => w.createdAt).reverse()
				break

			case 'updated':
				newFiltered = _.sortBy(newFiltered, w => w.updatedAt).reverse()
				break
		}

		switch (sortPostsBy) {
			case 'title':
				newFiltered = newFiltered.map(w => ({ ...w, nodes: _.sortBy(w.nodes, p => p.title) }))
				break

			case 'published':
				newFiltered = newFiltered.map(w => ({ ...w, nodes: _.sortBy(w.nodes, p => p.published).reverse() }))
				break
		}

		onFilter(newFiltered)
	}, [data, filterByUsed, filterByText, sortWidgetBy, sortPostsBy])

	return (
		<div className="filter-bar">
			<FormControl className="form-control">
				<InputLabel id="filterUsedLabel">Used?</InputLabel>
				<Select labelId="filterUsedLabel" label="Used?" value={filterByUsed} onChange={e => setFilterByUsed(e.target.value)} disabled={loading}>
					<MenuItem value="">All</MenuItem>
					<MenuItem value={true}>Yes</MenuItem>
					<MenuItem value={false}>No</MenuItem>
				</Select>
			</FormControl>

			<FormControl className="form-control">
				<InputLabel id="sortWidgetLabel">Sort Widgets</InputLabel>
				<Select labelId="sortWidgetLabel" label="Sort Widgets" value={sortWidgetBy} onChange={e => setSortWidgetBy(e.target.value)} disabled={loading}>
					<MenuItem value="name">Name</MenuItem>
					<MenuItem value="usage">Usage</MenuItem>
					<MenuItem value="created">Created Date</MenuItem>
					<MenuItem value="updated">Updated Date</MenuItem>
				</Select>
			</FormControl>

			<FormControl className="form-control">
				<InputLabel id="sortPostsLabel">Sort References</InputLabel>
				<Select labelId="sortPostsLabel" label="Sort References" value={sortPostsBy} onChange={e => setSortPostsBy(e.target.value)} disabled={loading}>
					<MenuItem value="title">Title</MenuItem>
					<MenuItem value="published">Publish Date</MenuItem>
				</Select>
			</FormControl>

			<FormControl className="form-control">
				<TextField disabled={loading} label="Search" variant="outlined" value={filterByText} onChange={e => setFilterBytext(e.target.value)} />
			</FormControl>
		</div>
	)
}
