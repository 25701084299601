import React, { useState } from 'react'
import WidgetsTree from './WidgetsTree'
import Pagination from '@mui/material/Pagination'
import { useEffect } from 'react'
import { Stack } from '@mui/material'

export default function TreePagination({ title, loading, displayedData, onPreview, onPreviewText, showNodesNumber }) {
	const PAGE_SIZE = 50

	const [numberOfPages, setNumberOfPages] = useState()
	const [currentPage, setCurrentPage] = useState(1)
	const [currentPageData, setCurrentPageData] = useState(displayedData.slice(0, PAGE_SIZE))

	useEffect(() => {
		setNumberOfPages(Math.ceil(displayedData?.length / PAGE_SIZE))
		setCurrentPageData(displayedData.slice(0, PAGE_SIZE))
	}, [displayedData])

	const onPageChange = (e, val) => {
		setCurrentPage(val)

		setCurrentPageData(displayedData.slice(PAGE_SIZE * (val - 1), PAGE_SIZE * val))
	}

	if (loading) return null

	return (
		<div className="content-container">
			<WidgetsTree
				title={title}
				displayedData={currentPageData}
				totalCount={displayedData?.length}
				onPreview={onPreview}
				onPreviewText={onPreviewText}
				showNodesNumber={showNodesNumber}
			/>

			{numberOfPages > 1 && (
				<div className="pagination-container">
					<Pagination count={numberOfPages} page={currentPage} onChange={onPageChange} size="large" color="primary" />
				</div>
			)}
		</div>
	)
}
