import { useState } from 'react'

import SponsoredBlockArticles from '../components/SponsoredBlockArticles'
import SponsoredBlockCategories from '../components/SponsoredBlockCategories'
import SponsoredBlockSubCategories from '../components/SponsoredBlockSubCategories'

import { Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Layout from '../components/layout/Layout'

export default function () {
	const [selectedTab, setSelectedTab] = useState('1')

	return (
		<Layout>
			<div className="tabs-container">
				<TabContext value={selectedTab}>
					<TabList onChange={(e, val) => setSelectedTab(val)}>
						<Tab label="Posts" value="1" />
						<Tab label="Categories" value="2" />
						<Tab label="Sub Categories" value="3" />
					</TabList>

					<TabPanel value="1" className="tab-content">
						<SponsoredBlockArticles />
					</TabPanel>
					<TabPanel value="2" className="tab-content">
						<SponsoredBlockCategories />
					</TabPanel>
					<TabPanel value="3" className="tab-content">
						<SponsoredBlockSubCategories />
					</TabPanel>
				</TabContext>
			</div>
		</Layout>
	)
}
