import React, { Fragment, useEffect, useState } from 'react'
import { useIdentityContext } from 'react-netlify-identity'
import axios from 'axios'

import { setKeys } from '../services/contentful'
import Loading from './Loading'

export default function LoadContentful({ children }) {
	const [loading, setLoading] = useState(true)
	const identity = useIdentityContext()

	useEffect(() => {
		loadKeys()
	}, [])

	const loadKeys = async () => {
		try {
			const freshToken = await identity.getFreshJWT()

			const options = {
				headers: {
					Authorization: `Bearer ${freshToken}`,
				},
			}
			const response = await axios('/.netlify/functions/keys', options)
			const newKeys = response.data

			setKeys(newKeys)
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}

	return loading ? <Loading loading={true} /> : <Fragment>{children}</Fragment>
}

