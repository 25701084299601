import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export default function TooltipIcon({ text }) {
	return (
		<Tooltip title={text}>
			<InfoOutlinedIcon fontSize="small" htmlColor="#666" style={{ marginRight: '5px' }} />
		</Tooltip>
	)
}
