import { useEffect, useState, useContext, Fragment } from 'react'

import _ from 'lodash'

import { InputLabel, Select, Button, IconButton, FormControl, MenuItem, Tooltip, TextField, FormControlLabel, Switch } from '@mui/material'
import TooltipIcon from './TooltipIcon'

export default function PostsFilterBar({ data, onFilter, loading }) {
	const [sortPostsBy, setSortPostsBy] = useState('')

	const [filterByTitle, setFilterByTitle] = useState('')
	const [filterBySlug, setFilterBySlug] = useState('')
	const [filterByBody, setFilterByBody] = useState('')

	const [filterByOutgoingLinksFrom, setFilterByOutgoingLinksFrom] = useState('')
	const [filterByOutgoingLinksTo, setFilterByOutgoingLinksTo] = useState('')
	const [filterByBacklinksFrom, setFilterByBacklinksFrom] = useState('')
	const [filterByBacklinksTo, setFilterByBacklinksTo] = useState('')
	const [filterByCtaWidgetsFrom, setFilterByCtaWidgetsFrom] = useState('')
	const [filterByCtaWidgetsTo, setFilterByCtaWidgetsTo] = useState('')

	const [switchCtaWidget, setSwitchCtaWidget] = useState(true)
	const [switchTopOffers, setSwitchTopOffers] = useState(true)
	const [switchMyFinance, setSwitchMyFinance] = useState(true)
	const [switchOurPicks, setSwitchOurPicks] = useState(true)
	const [switchCommonBlock, setSwitchCommonBlock] = useState(true)
	const [switchTracking, setSwitchTracking] = useState(true)
	const [switchContentfulPost, setSwitchContentfulPost] = useState(true)
	const [switchSponsoredBlock, setSwitchSponsoredBlock] = useState(true)
	const [switchComparisonTable, setSwitchComparisonTable] = useState(true)
	const [switchTopStickyBar, setSwitchTopStickyBar] = useState(true)
	const [switchInternalLink, setSwitchInternalLink] = useState(true)
	const [switchExternalLink, setSwitchExternalLink] = useState(true)
	const [switchContentfulBacklink, setSwitchContentfulBacklink] = useState(true)
	const [switchUrlBacklink, setSwitchUrlBacklink] = useState(true)

	useEffect(() => {
		let newFiltered = data

		if (filterByTitle.length > 0) {
			newFiltered = newFiltered.filter(p => p.title?.toLowerCase().includes(filterByTitle.toLowerCase()))
		}

		if (filterBySlug.length > 0) {
			newFiltered = newFiltered.filter(p => p.slug?.toLowerCase().includes(filterBySlug.toLowerCase()))
		}

		if (filterByBody.length > 0) {
			newFiltered = newFiltered.filter(p => p.body?.toLowerCase().includes(filterByBody.toLowerCase()))
		}

		if (filterByOutgoingLinksFrom !== '') {
			newFiltered = newFiltered.filter(p => p.outgoingLinksCount >= filterByOutgoingLinksFrom)
		}

		if (filterByOutgoingLinksTo !== '') {
			newFiltered = newFiltered.filter(p => p.outgoingLinksCount <= filterByOutgoingLinksTo)
		}

		if (filterByBacklinksFrom !== '') {
			newFiltered = newFiltered.filter(p => p.backlinksCount >= filterByBacklinksFrom)
		}

		if (filterByBacklinksTo !== '') {
			newFiltered = newFiltered.filter(p => p.backlinksCount <= filterByBacklinksTo)
		}

		if (filterByCtaWidgetsFrom !== '') {
			newFiltered = newFiltered.filter(p => p.ctaWidgetsCount >= filterByCtaWidgetsFrom)
		}

		if (filterByCtaWidgetsTo !== '') {
			newFiltered = newFiltered.filter(p => p.ctaWidgetsCount <= filterByCtaWidgetsTo)
		}

		//swicthes

		if (!switchCtaWidget) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('CTA Widget')) }))
		}

		if (!switchTopOffers) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Top Offers')) }))
		}

		if (!switchMyFinance) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('My Finance')) }))
		}

		if (!switchOurPicks) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Our Picks')) }))
		}

		if (!switchCommonBlock) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Common Block')) }))
		}

		if (!switchTracking) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Tracking Link')) }))
		}

		if (!switchContentfulPost) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Post Link')) }))
		}

		if (!switchSponsoredBlock) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Sponsored Block')) }))
		}

		if (!switchComparisonTable) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Comparison Table')) }))
		}

		if (!switchTopStickyBar) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Top Sticky Bar')) }))
		}

		if (!switchInternalLink) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Outgoing Internal Link')) }))
		}

		if (!switchExternalLink) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Outgoing External Link')) }))
		}

		if (!switchContentfulBacklink) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('Contentful Backlink')) }))
		}

		if (!switchUrlBacklink) {
			newFiltered = newFiltered.map(p => ({ ...p, nodes: p.nodes.filter(n => !n.title?.startsWith('URL Backlink')) }))
		}

		switch (sortPostsBy) {
			case 'title':
				newFiltered = _.sortBy(newFiltered, p => p.title)
				break

			case 'published':
				newFiltered = _.sortBy(newFiltered, p => p.published).reverse()
				break
		}

		onFilter(newFiltered)
	}, [
		data,
		filterByTitle,
		filterBySlug,
		filterByBody,
		filterByOutgoingLinksFrom,
		filterByOutgoingLinksTo,
		filterByBacklinksFrom,
		filterByBacklinksTo,
		filterByCtaWidgetsFrom,
		filterByCtaWidgetsTo,
		sortPostsBy,
		switchCtaWidget,
		switchTopOffers,
		switchMyFinance,
		switchOurPicks,
		switchCommonBlock,
		switchTracking,
		switchContentfulPost,
		switchSponsoredBlock,
		switchComparisonTable,
		switchTopStickyBar,
		switchInternalLink,
		switchExternalLink,
		switchContentfulBacklink,
		switchUrlBacklink,
	])

	return (
		<div className="filter-bar">
			<div className="posts-filter-bar">
				<FormControl className="form-control">
					<InputLabel id="sortPostsLabel">Sort Posts</InputLabel>
					<Select labelId="sortPostsLabel" label="Sort Posts" value={sortPostsBy} onChange={e => setSortPostsBy(e.target.value)} disabled={loading}>
						<MenuItem value="title">Title</MenuItem>
						<MenuItem value="published">Publish Date</MenuItem>
					</Select>
				</FormControl>
				<FormControl className="form-control">
					<TextField disabled={loading} label="Title" variant="outlined" value={filterByTitle} onChange={e => setFilterByTitle(e.target.value)} />
				</FormControl>
				<FormControl className="form-control">
					<TextField disabled={loading} label="Slug" variant="outlined" value={filterBySlug} onChange={e => setFilterBySlug(e.target.value)} />
				</FormControl>
				<FormControl className="form-control">
					<TextField disabled={loading} label="Body" variant="outlined" value={filterByBody} onChange={e => setFilterByBody(e.target.value)} />
				</FormControl>
			</div>

			<div className="posts-filter-bar">
				<div className="item">
					<TooltipIcon text="Contentful Post Links, Contentful Tracking Links and Internal Links" />
					<span className="label">Outgoing Links:</span>
					<FormControl className="form-control small">
						<TextField
							disabled={loading}
							size="small"
							label="From"
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 100 }}
							value={filterByOutgoingLinksFrom}
							onChange={e => setFilterByOutgoingLinksFrom(e.target.value)}
						/>
					</FormControl>
					<FormControl className="form-control small">
						<TextField
							disabled={loading}
							size="small"
							label="To"
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 100 }}
							value={filterByOutgoingLinksTo}
							onChange={e => setFilterByOutgoingLinksTo(e.target.value)}
						/>
					</FormControl>
				</div>

				<div className="item">
					<TooltipIcon text="Backlinks through Contentful Post and URLs" />
					<span className="label">Backlinks:</span>
					<FormControl className="form-control small">
						<TextField
							disabled={loading}
							size="small"
							label="From"
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 100 }}
							value={filterByBacklinksFrom}
							onChange={e => setFilterByBacklinksFrom(e.target.value)}
						/>
					</FormControl>
					<FormControl className="form-control small">
						<TextField
							disabled={loading}
							size="small"
							label="To"
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 100 }}
							value={filterByBacklinksTo}
							onChange={e => setFilterByBacklinksTo(e.target.value)}
						/>
					</FormControl>
				</div>

				<div className="item">
					<span className="label">CTA Widgets:</span>
					<FormControl className="form-control small">
						<TextField
							disabled={loading}
							size="small"
							label="From"
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 100 }}
							value={filterByCtaWidgetsFrom}
							onChange={e => setFilterByCtaWidgetsFrom(e.target.value)}
						/>
					</FormControl>
					<FormControl className="form-control small">
						<TextField
							disabled={loading}
							size="small"
							label="To"
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 100 }}
							value={filterByCtaWidgetsTo}
							onChange={e => setFilterByCtaWidgetsTo(e.target.value)}
						/>
					</FormControl>
				</div>
			</div>
			<div className="posts-filter-bar switches">
				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchCtaWidget} onChange={e => setSwitchCtaWidget(e.target.checked)} />}
					label="Show CTA Widget"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchTopStickyBar} onChange={e => setSwitchTopStickyBar(e.target.checked)} />}
					label="Show Top Sticky Bar"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchMyFinance} onChange={e => setSwitchMyFinance(e.target.checked)} />}
					label="Show My Finance"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchOurPicks} onChange={e => setSwitchOurPicks(e.target.checked)} />}
					label="Show Our Picks"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchCommonBlock} onChange={e => setSwitchCommonBlock(e.target.checked)} />}
					label="Show Common Block"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchTopOffers} onChange={e => setSwitchTopOffers(e.target.checked)} />}
					label="Show Top Offers"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchSponsoredBlock} onChange={e => setSwitchSponsoredBlock(e.target.checked)} />}
					label="Show Sponsored Block"
				/>
				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchComparisonTable} onChange={e => setSwitchComparisonTable(e.target.checked)} />}
					label="Show Comparison Table"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchTracking} onChange={e => setSwitchTracking(e.target.checked)} />}
					label="Show Contentful Tracking"
				/>
				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchContentfulPost} onChange={e => setSwitchContentfulPost(e.target.checked)} />}
					label="Show Contentful Post"
				/>

				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchInternalLink} onChange={e => setSwitchInternalLink(e.target.checked)} />}
					label="Show Outgoing Internal Link"
				/>
				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchExternalLink} onChange={e => setSwitchExternalLink(e.target.checked)} />}
					label="Show Outgoing External Link"
				/>
				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchContentfulBacklink} onChange={e => setSwitchContentfulBacklink(e.target.checked)} />}
					label="Show Contentful Backlink"
				/>
				<FormControlLabel
					control={<Switch disabled={loading} size="small" checked={switchUrlBacklink} onChange={e => setSwitchUrlBacklink(e.target.checked)} />}
					label="Show URL Backlink"
				/>
			</div>
		</div>
	)
}

