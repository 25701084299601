import { BLOCKS, INLINES } from '@contentful/rich-text-types'

export const richTextOptions = {
	renderNode: {
		[BLOCKS.EMBEDDED_ENTRY]: () => null,
		[BLOCKS.EMBEDDED_ASSET]: () => null,
		[INLINES.EMBEDDED_ENTRY]: () => null,
		[INLINES.ENTRY_HYPERLINK]: () => null,
		[INLINES.ASSET_HYPERLINK]: () => null,
	},
}
