import React from 'react'
import Link from '@mui/material/Link'

import { Button } from '@mui/material'
import { withRouter } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

function Navigation({ location }) {
	const paths = [
		{ title: 'Home', path: '/' },
		{ title: 'Post', path: '/post' },
		{ title: 'CTA Widget', path: '/cta-widget' },
		{ title: 'Top Sticky Bar', path: '/top-sticky-bar' },
	]

	return (
		<div>
			{paths.map(({ title, path }) => (
				<Button key={path} variant={location.pathname === path ? 'outlined' : 'text'} to={path} style={{ margin: '0 5px' }} component={RouterLink}>
					{title}
				</Button>
			))}
		</div>
	)
}

export default withRouter(Navigation)
