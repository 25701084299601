import { useEffect, useState, useContext, Fragment } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import TreeItem from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone'
import { Button, Chip, Stack } from '@mui/material'

export default function WidgetsTree({ title, loading, displayedData, totalCount, showNodesNumber, onPreview, onPreviewText = 'Preview' }) {
	if (loading) return null

	const [expanded, setExpanded] = useState([])

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds)
	}

	const handleExpandClick = () => {
		setExpanded(oldExpanded => (oldExpanded.length === 0 ? displayedData.map(w => w.id) : []))
	}

	useEffect(() => {
		setExpanded([])
	}, [displayedData])

	return (
		<div>
			<div className="expand-container">
				<span className="title">
					{title} ({totalCount || displayedData.length})
				</span>
				<Button onClick={handleExpandClick}>{expanded.length === 0 ? 'Expand all' : 'Collapse all'}</Button>
			</div>

			<div className="tree-container">
				<TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} expanded={expanded} onNodeToggle={handleToggle}>
					{displayedData.map(widget => (
						<TreeItem
							key={widget.id}
							nodeId={widget.id}
							label={`${widget.name}   ${showNodesNumber ? '(' + widget?.nodes?.length + ')' : ''} `}
							style={{ color: '#000', padding: '5px 0' }}>
							<div style={{ margin: '10px 30px' }}>
								<Button variant="contained" disableElevation href={widget.contentful} target="_blank">
									Contentful
								</Button>

								{onPreview && (
									<Button variant="contained" disableElevation onClick={() => onPreview(widget)}>
										{onPreviewText}
									</Button>
								)}

								{widget.copyTitle && (
									<CopyToClipboard text={widget.copyTitle}>
										<Button variant="text" startIcon={<ContentCopyTwoToneIcon />}>
											Title
										</Button>
									</CopyToClipboard>
								)}

								{widget.copySlug && (
									<CopyToClipboard text={widget.copySlug}>
										<Button variant="text" startIcon={<ContentCopyTwoToneIcon />}>
											Slug
										</Button>
									</CopyToClipboard>
								)}

								{widget.copyUrl && (
									<CopyToClipboard text={widget.copyUrl}>
										<Button variant="text" startIcon={<ContentCopyTwoToneIcon />}>
											URL
										</Button>
									</CopyToClipboard>
								)}
							</div>

							{widget.nodes?.map(node => (
								<TreeItem key={widget.id + node?.id} nodeId={widget.id + node?.id} label={node?.title} style={{ color: '#666', padding: '5px 0' }}>
									<div style={{ margin: '10px 30px' }}>
										{node.contentful && (
											<Button variant="outlined" href={node.contentful} target="_blank">
												Contentful
											</Button>
										)}

										{node.url && (
											<Button variant="outlined" href={node.url} target="_blank">
												Open
											</Button>
										)}

										{node.copyTitle && (
											<CopyToClipboard text={node.copyTitle}>
												<Button variant="text" startIcon={<ContentCopyTwoToneIcon />}>
													Title
												</Button>
											</CopyToClipboard>
										)}

										{node.copySlug && (
											<CopyToClipboard text={node.copySlug}>
												<Button variant="text" startIcon={<ContentCopyTwoToneIcon />}>
													Slug
												</Button>
											</CopyToClipboard>
										)}

										{node.copyUrl && (
											<CopyToClipboard text={node.copyUrl}>
												<Button variant="text" startIcon={<ContentCopyTwoToneIcon />}>
													URL
												</Button>
											</CopyToClipboard>
										)}
									</div>
								</TreeItem>
							))}
						</TreeItem>
					))}
				</TreeView>
			</div>
		</div>
	)
}
