import React from 'react'
import ReactDOM from 'react-dom'
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import App from './App'
import './styles.scss'

const theme = createTheme({
	palette: {
		primary: {
			main: '#333D8B',
		},
		secondary: {
			main: '#25BFB8',
		},
		background: {
			default: '#F1FAFA',
		},
	},
})

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<App />
	</ThemeProvider>,
	document.getElementById('root'),
)
